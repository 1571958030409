import * as ActionTypes from './ActionTypes';

import { getRequest, postRequest, putRequest, deleteRequest } from '@snc/api';

export const fetchLauncher = () => (dispatch, getState) => {
    const config = getState().config.config;
    const getLauncherUrl = [config.launcher.api, 'launchers', config.launcher.launcher_id].join('/');

    return getRequest(`${getLauncherUrl}?clientId=1`).then(launcher => dispatch(loadLauncher(launcher)));
};

export const loadLauncher = launcher => ({
    type: ActionTypes.LOAD_LINKS,
    launcher: launcher,
    links: launcher.links,
});

export const postLink = link => (dispatch, getState) => {
    const config = getState().config.config;
    const postLinkUrl = [config.launcher.api, 'launchers', config.launcher.launcher_id, 'links'].join('/');

    return postRequest(postLinkUrl, link).then(() => dispatch(fetchLauncher()));
};

export const linkPosted = () => ({
    type: ActionTypes.LINK_POSTED,
});

export const putLink = link => (dispatch, getState) => {
    const config = getState().config.config;
    const putLinkUrl = [config.launcher.api, 'launchers', config.launcher.launcher_id, 'links', link.id].join('/');

    return putRequest(putLinkUrl, link).then(() => dispatch(fetchLauncher()));
};

export const linkPutted = () => ({
    type: ActionTypes.LINK_PUTTED,
});

export const deleteLink = link => (dispatch, getState) => {
    const config = getState().config.config;
    const deleteLinkUrl = [config.launcher.api, 'launchers', config.launcher.launcher_id, 'links', link.id].join('/');

    return deleteRequest(deleteLinkUrl).then(() => dispatch(fetchLauncher()));
};

export const linkDeleted = () => ({
    type: ActionTypes.LINK_DELETED,
});
