import React from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { useEmiConfig } from '@snc/emi-config';
import merge from 'lodash/merge';
import { sanitizeThemeConfig } from './themeUtils';
import { Loader } from '../Loader/Loader';

const palette = {
    primary: {
        main: '#FF4347',
        contrastText: '#FFF',
    },
    background: {
        default: '#E3E3E3',
        paper: '#FFF',
    },
};

const shape = { borderRadius: '0.4rem' };

const DEFAULT_THEME = createTheme({ palette, shape, overrides: {} });

export const ThemeContext = React.createContext();

export const ThemeProvider = props => {
    const config = useEmiConfig();
    const [theme, setThemeInternal] = React.useState(createTheme(DEFAULT_THEME));

    const setTheme = React.useCallback(theme => {
        setThemeInternal(createTheme(merge(DEFAULT_THEME, theme)));
    }, []);

    React.useEffect(() => {
        if (!config) return;
        const newThemeConfig = sanitizeThemeConfig(config.THEME, config.API_BASE_URI);

        if (newThemeConfig) {
            setTheme(createTheme(newThemeConfig));
        }
    }, [config, setTheme]);

    if (!config) {
        return <Loader />;
    }

    return (
        <ThemeContext.Provider value={{ setTheme }}>
            <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
        </ThemeContext.Provider>
    );
};
