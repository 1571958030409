import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    Tooltip,
    Divider,
    CardContent,
    makeStyles,
    useTheme,
    Grid,
    Typography,
    IconButton,
    Box,
    Button,
} from '@material-ui/core';
import clsx from 'clsx';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SaveIcon from '@material-ui/icons/Save';
import { i18n } from 'i18n';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Searcher } from 'components/generic/search/Searcher';
import Autocomplete from 'components/generic/autocomplete/Autocomplete';
import RolesList from './list/RolesList';
import RolesDetails from './RolesDetails';

const activeOptions = [
    {
        value: true,
        label: i18n.actives,
    },
    {
        value: false,
        label: i18n.noActives,
    },
    {
        value: undefined,
        label: i18n.all,
    },
];

const Roles = props => {
    const {
        roles,
        selectedRole,
        setSelectedRole,
        update,
        create,
        searchText,
        setSearchText,
        setPage,
        formikRef,
        isLoading,
        active,
        setActive,
        selectedAppId,
        setSelectedAppId,
        selectedModule,
        setSelectedModule,
    } = props;

    const [isNewRole, setIsNewRole] = useState(false);

    const classes = useStyles();
    const theme = useTheme();

    const disabledClassname = clsx({
        [classes.disabled]: !(selectedRole || isNewRole),
    });

    const loadingClassname = clsx({
        [classes.loading]: isLoading,
    });

    const handleIsNewRole = () => {
        setIsNewRole(true);
        setSelectedRole();
    };

    const handleSaveRole = values => {
        if (selectedRole?.roleId) {
            const updatedRole = {
                ...selectedRole,
                ...values,
                permissions: (values?.permissions ?? []).map(p => ({ roleId: selectedRole.roleId, permissionId: p.permissionId })),
            };

            update(updatedRole);
        } else {
            const newRole = {
                ...values,
                permissions: (values?.permissions ?? []).map(p => ({ permissionId: p.permissionId })),
                sonRoles: [],
            };

            create(newRole);
        }

        setIsNewRole(false);
    };

    return (
        <Card className={`w-100 h-100 d-flex flex-column ${loadingClassname}`}>
            <CardHeader
                classes={{ action: classes.cardHeaderContent }}
                title={i18n.roles}
                action={
                    <Box display="flex">
                        <Tooltip title={i18n.addGroup}>
                            <Button style={{ border: '1px solid', marginRight: theme.spacing(1) }} onClick={() => handleIsNewRole(true)}>
                                <AddCircleOutlineIcon style={{ marginRight: theme.spacing(1) }} /> {i18n.addRole}
                            </Button>
                        </Tooltip>
                        <Tooltip title={i18n.save}>
                            <Button
                                style={{ border: '1px solid' }}
                                onClick={() => {
                                    if (formikRef.current) formikRef.current.handleSubmit();
                                }}
                            >
                                <SaveIcon style={{ marginRight: theme.spacing(1) }} /> {i18n.save}
                            </Button>
                        </Tooltip>
                    </Box>
                }
            />
            <Divider />
            <CardContent className="h-100 w-100 row no-gutters flex-grow-1 overflow-auto pb-1">
                <div className="col-3 pl-2 pr-4 h-100 d-flex flex-column flex-nowrap" style={{ borderRight: '1px solid lightgrey' }}>
                    <Grid item container xs={12} style={{ flexBasis: 'auto' }}>
                        <Grid item xs={6} style={{ paddingRight: theme.spacing(2) }}>
                            <Searcher
                                value={searchText}
                                onChange={value => {
                                    setSearchText(value);
                                    setPage(0);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                margin={'none'}
                                className={classes.autocomplete}
                                key={'value'}
                                getOptionLabel={item => item.label}
                                value={activeOptions.find(item => item.value === active)}
                                getOptionSelected={option => option.value === active}
                                options={activeOptions}
                                onChange={newValue => {
                                    if (!newValue) return;

                                    setActive(newValue.value);
                                    setPage(0);
                                }}
                                label={i18n.status}
                            />
                        </Grid>
                    </Grid>

                    <div className="w-100 mt-2 overflow-auto flex-grow-1" style={{ overflowY: 'auto' }}>
                        <RolesList
                            selectedRole={selectedRole}
                            setSelectedRole={role => {
                                setSelectedRole(role);
                                setIsNewRole(false);
                            }}
                            roles={roles?.results ?? []}
                            isLoading={isLoading}
                        />
                    </div>

                    <Grid item container justifyContent={'space-between'} display={'flex'} alignItems={'center'}>
                        <Grid item display={'flex'} alignItems={'center'}>
                            <Typography>
                                {!!roles && !!roles.results
                                    ? `${roles.page * roles.size + 1} - ${roles.page * roles.size + roles.results.length} de ${
                                          roles.rownum
                                      }`
                                    : ''}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton
                                disabled={roles.page <= 0}
                                onClick={() => {
                                    if (!!roles && roles.page > 0) {
                                        setSearchText('');
                                        setPage(prev => prev - 1);
                                    }
                                }}
                            >
                                <NavigateBeforeIcon />
                            </IconButton>
                            <IconButton
                                disabled={roles.page >= roles.pageCount}
                                onClick={() => {
                                    if (!!roles && roles.page * roles.size + roles.results.length < roles.rownum) {
                                        setPage(prev => prev + 1);
                                    }
                                }}
                            >
                                <NavigateNextIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
                <div className={`col-9 w-100 h-100 p-3 d-flex flex-column ${disabledClassname}`}>
                    <RolesDetails
                        newRole={isNewRole}
                        selectedRole={selectedRole}
                        handleSaveRole={handleSaveRole}
                        formikRef={formikRef}
                        selectedAppId={selectedAppId}
                        setSelectedAppId={setSelectedAppId}
                        selectedModule={selectedModule}
                        setSelectedModule={setSelectedModule}
                    />
                </div>
            </CardContent>
        </Card>
    );
};

const useStyles = makeStyles(() => ({
    disabled: {
        pointerEvents: 'none',
        opacity: 0.4,
    },
    loading: {
        pointerEvents: 'none',
        opacity: 0.7,
    },
    cardHeaderContent: {
        margin: 0,
    },
}));

export default Roles;
