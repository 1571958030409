import * as ActionTypes from './ActionTypes';
import Axios from 'axios';

export const fetchPermissions = () => (dispatch, getState) => {
    const config = getState().config.config;
    const getPermissionsUrl = `${config.AUTH.BASE}/permissions`;

    return Axios.get(getPermissionsUrl)
        .then(
            response => {
                if (response.status === 200) return response;
                else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then(response => response.data)
        .then(permissions => dispatch(permissionsLoad(permissions)))
        .catch(error => dispatch(permissionsLoadFailed(error.message)));
};

export const fetchRolePermissions = roleId => (dispatch, getState) => {
    const config = getState().config.config;
    const getRolePermissions = `${config.AUTH.BASE}/roles/${roleId}/permissions`;

    return Axios.get(getRolePermissions)
        .then(
            response => {
                if (response.status === 200) return response;
                else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then(response => response.data)
        .then(permissions => dispatch(loadCheckedPermissions(permissions)))
        .catch(error => dispatch(permissionsLoadFailed(error.message)));
};

export const permissionsLoad = loadedPermissions => ({
    type: ActionTypes.LOAD_PERMISSIONS,
    payload: loadedPermissions,
});

export const permissionsLoadFailed = errMess => ({
    type: ActionTypes.PERMISSIONS_LOAD_FAILED,
    payload: errMess,
});

export const permissionsLoading = () => ({
    type: ActionTypes.PERMISSIONS_LOADING,
});

export const loadCheckedPermissions = checked => ({
    type: ActionTypes.PERMISSIONS_LOAD_CHECKED,
    payload: checked,
});

export const setCheckedPermissions = checked => ({
    type: ActionTypes.SET_CHECKED_PERMISSIONS,
    payload: checked,
});
