import React, { useState } from 'react';
import { makeStyles, Typography, useTheme } from '@material-ui/core';
import { Person, Group, Lock, SettingsInputComponent, LockOpen, PersonOutline, VpnKey } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Navigator as EmiNavigator } from '@snc/router-ui';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { i18n } from 'i18n';
import { Routes as routes } from 'utils/routes';
import { canViewMenuItem } from '../generic/permissions/permission-utils';
import { MODULES } from 'utils/module-utils';
import GroupsWrapper from './groups/GroupsWrapper';
import RolesWrapper from './roles/RolesWrapper';
import UsersWrapper from './users/UsersWrapper';
import ChangeMyPassword from './profile/changeMyPassword';
import Profile from './profile/Profile';
import { AssignmentWrapper } from './assignment/AssignmentWrapper';
import { useEmiConfig } from '@snc/emi-config';
import { useSelector } from 'react-redux';

const Administration = () => {
    const classes = useStyles();

    const history = useHistory();

    const theme = useTheme();

    const [selectedItem, setSelectedItem] = useState(null);
    const [expandedIds, setExpandedIds] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);

    const location = useLocation();

    const config = useEmiConfig();

    const { modules } = useSelector(state => state.apps);
    const { permissions } = useSelector(state => state.permissions);
    const { loggedUser } = useSelector(state => state.users);
    const { roles } = useSelector(state => state.roles);

    const version = process.env.REACT_APP_VERSION;

    React.useEffect(() => {
        document.body.style = 'background-image: unset !important;';

        return () => {
            if (config?.LAUNCHER?.BG_IMG)
                document.body.style = `background-image: url("${process.env.PUBLIC_URL}/${config.LAUNCHER.BG_IMG}") !important;`;
        };
    }, [config]);

    const { items, menus } = React.useMemo(() => {
        const items = [
            {
                id: 'tab-default',
                label: i18n.administration,
                route: `${routes.administration}`,
                hidden: true,
                isExact: true,
                content: () => (
                    <Box height={1} width={1} display="flex" justifyContent="center" alignItems="center">
                        <Paper
                            className={classes.paper}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography variant="body1">{i18n.administrationWelcome}</Typography>
                            <Typography variant="body1">{i18n.administrationSelectOption}</Typography>
                        </Paper>
                    </Box>
                ),
                subitems: [],
            },
            {
                id: 'tab-access',
                label: i18n.access,
                module: MODULES.AdministrationAccessSection.name,
                perm: MODULES.AdministrationAccessSection.perms.view,
                icon: LockOpen,
                subitems: [
                    {
                        id: 'tab-access-users',
                        label: i18n.users,
                        icon: Person,
                        route: `${routes.administrationUsers}`,
                        content: () => (
                            <Box height={1} width={1} display="flex" justifyContent="center" alignItems="center">
                                <UsersWrapper />
                            </Box>
                        ),
                    },
                    {
                        id: 'tab-access-groups',
                        label: i18n.groups,
                        icon: Group,
                        route: `${routes.administrationGroups}`,
                        content: () => (
                            <Box height={1} width={1} display="flex" justifyContent="center" alignItems="center">
                                <GroupsWrapper />
                            </Box>
                        ),
                    },
                    {
                        id: 'tab-access-roles',
                        label: i18n.roles,
                        icon: Lock,
                        hidden: !config?.launcher?.showRolesTab,
                        route: `${routes.administrationRoles}`,
                        content: () => (
                            <Box height={1} width={1} display="flex" justifyContent="center" alignItems="center">
                                <RolesWrapper />
                            </Box>
                        ),
                    },
                    {
                        id: 'tab-access-assignment',
                        label: i18n.assignment,
                        icon: SettingsInputComponent,
                        route: `${routes.administrationAssigment}`,
                        content: () => (
                            <Box height={1} width={1} display="flex" justifyContent="center" alignItems="center">
                                <AssignmentWrapper />
                            </Box>
                        ),
                    },
                ],
            },
            {
                id: 'tab-user',
                label: i18n.myProfile,
                icon: PersonOutline,
                subitems: [
                    {
                        id: 'tab-user-profile',
                        label: i18n.myProfile,
                        icon: Person,
                        route: `${routes.administrationProfile}`,
                        content: () => (
                            <Box height={1} width={1} display="flex" justifyContent="center" alignItems="center">
                                <Profile />
                            </Box>
                        ),
                    },
                    {
                        id: 'tab-access-password',
                        label: i18n.changePassword,
                        icon: VpnKey,
                        route: `${routes.administrationProfileChangeMyPassword}`,
                        content: () => (
                            <Box height={1} width={1} display="flex" justifyContent="center" alignItems="center">
                                <ChangeMyPassword />
                            </Box>
                        ),
                    },
                ],
            },
        ];

        const { groups } = loggedUser || {};

        const filteredItems = items
            .filter(canViewMenuItem(groups || [], modules, roles, permissions, config.launcher.app_id))
            .map(item => ({ ...item, subitems: (item?.subitems ?? []).filter(subitem => !subitem.hidden) }));

        const menus = filteredItems.reduce((acc, item) => (item?.subitems?.length ? [...acc, ...item.subitems] : [...acc, item]), []);

        return { items: filteredItems, menus };
    }, [classes.paper, config, loggedUser, modules, permissions, roles]);

    React.useEffect(() => {
        if (!hasLoaded) {
            const currentMenu = menus.find(menu => menu.route === location.pathname);

            if (currentMenu) {
                setSelectedItem(currentMenu);

                const parent = items.find(item => {
                    if (item.route === location.pathname) return true;

                    const subitems = item?.subitems ?? [];

                    if (subitems?.length) return subitems.some(subitem => subitem.route === location.pathname);

                    return false;
                });

                if (parent) setExpandedIds([parent.id]);
            }

            setHasLoaded(true);
        }
    }, [menus, items, hasLoaded, location.pathname]);

    return (
        <Box width={1} height={1} p={2}>
            <Grid container direction="row" className={classes.grid} wrap="nowrap">
                {!collapsed && (
                    <Grid item xs={12} md={2} className={classes.options}>
                        <EmiNavigator
                            items={items.filter(item => !item.hidden)}
                            onClick={(item, expandableItem) => {
                                setSelectedItem(item);

                                if (expandableItem) {
                                    expandedIds.includes(item.id)
                                        ? setExpandedIds(expandedIds.filter(id => id !== item.id))
                                        : setExpandedIds([...expandedIds, item.id]);
                                } else {
                                    history.push(item.route);
                                }
                            }}
                            selectedItem={selectedItem}
                            color={'primary'}
                            component={Paper}
                            footer={
                                <Box px={1}>
                                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                                        v{version}
                                    </Typography>
                                </Box>
                            }
                            expandedItemIds={expandedIds}
                            isLoading={false}
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                            customItem={
                                <Box width={1} display="flex" paddingLeft={2} alignItems="center">
                                    <IconButton onClick={() => setCollapsed(prevState => !prevState)}>
                                        {collapsed ? <DoubleArrowIcon /> : <DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />}
                                    </IconButton>
                                </Box>
                            }
                        />
                    </Grid>
                )}

                {collapsed && (
                    <Grid item>
                        <Paper className={classes.paper} style={{ padding: theme.spacing(2), paddingTop: theme.spacing(1) }}>
                            <IconButton onClick={() => setCollapsed(prevState => !prevState)}>
                                <DoubleArrowIcon />
                            </IconButton>
                        </Paper>
                    </Grid>
                )}

                <Grid item xs={collapsed ? undefined : 10} md={collapsed ? undefined : 10} className={classes.content}>
                    <Box
                        width={1}
                        height={1}
                        display="flex"
                        flexDirection="column"
                        className={collapsed ? classes.contentCollapsed : classes.container}
                    >
                        <Switch>
                            {menus.map(({ id, route, content, isExact = false }) => {
                                return (
                                    <Route
                                        exact={isExact}
                                        path={route}
                                        render={() => {
                                            if (!content) return null;

                                            return content();
                                        }}
                                        key={id}
                                    />
                                );
                            })}
                        </Switch>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Administration;

const useStyles = makeStyles(theme => ({
    grid: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    options: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        height: '100%',
        flexGrow: 1,
        overflowX: 'auto',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 24,
            paddingBottom: 24,
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 16,
        },
    },
    container: {
        width: '100%',
        height: '100%',
    },
    paper: {
        width: '100%',
        height: '100%',
    },
    version: {
        color: '#FFF',
    },
}));
