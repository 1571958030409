import * as ActionTypes from '../../administration/users/ActionTypes';

export const Assignment = (
    state = {
        groups: [],
    },
    action
) => {
    switch (action.type) {
        case ActionTypes.LOAD_USER_GROUPS:
            return { ...state, groups: action.payload };
        default:
            return state;
    }
};
