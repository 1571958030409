import * as ActionTypes from '../../administration/users/ActionTypes';

export const Users = (
    state = {
        isLoading: true,
        loggedUser: {},

        usersLoading: false,
        usersErrMess: null,
        users: [],

        isUpdating: false,
        isCreating: false,
        updateErrMess: null,
        createErrMess: null,
    },
    action
) => {
    switch (action.type) {
        case ActionTypes.LOAD_USERS:
            return {
                ...state,
                usersLoading: false,
                isUpdating: false,
                isCreating: false,
                usersErrMess: null,
                users: action.payload,
            };
        case ActionTypes.USERS_LOADING:
            return { ...state, usersLoading: true, usersErrMess: null };
        case ActionTypes.USERS_LOAD_FAILED:
            return { ...state, usersLoading: false, usersErrMess: action.payload };

        case ActionTypes.USER_LOADING:
            return { ...state, isLoading: true };
        case ActionTypes.USER_LOGGED_IN:
            return {
                ...state,
                isLoading: false,
                loggedUser: action.payload,
            };

        case ActionTypes.USERS_UPDATING:
            return {
                ...state,
                isLoading: false,
                updateErrMess: null,
                isUpdating: true,
            };
        case ActionTypes.USERS_UPDATING_FAILED:
            return {
                ...state,
                isLoading: false,
                updateErrMess: action.payload,
                isUpdating: false,
            };
        case ActionTypes.USERS_UPDATED:
            return {
                ...state,
                isLoading: false,
                updateErrMess: null,
                isUpdating: false,
            };
        case ActionTypes.USERS_CREATING:
            return {
                ...state,
                isLoading: false,
                createErrMess: null,
                isCreating: true,
            };
        case ActionTypes.USERS_CREATING_FAILED:
            return {
                ...state,
                isLoading: false,
                createErrMess: action.payload,
                isCreating: false,
            };
        case ActionTypes.USERS_CREATED:
            return {
                ...state,
                isLoading: false,
                createErrMess: null,
                isCreating: false,
            };
        default:
            return state;
    }
};
