import * as ActionTypes from '../config/ActionTypes';

export const Config = (
    state = {
        isLoading: true,
        errMess: null,
        config: {},
    },
    action
) => {
    switch (action.type) {
        case ActionTypes.LOAD_CONFIG:
            return { ...state, isLoading: false, errMess: null, config: action.payload };
        case ActionTypes.LOAD_CONFIG_LOADING:
            return { ...state, isLoading: true, errMess: null, config: {} };
        case ActionTypes.LOAD_CONFIG_FAILED:
            return { ...state, isLoading: false, errMess: action.payload, config: {} };
        default:
            return state;
    }
};
