import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { Config } from './reducers/config';

//#region Administration
import { Users } from './reducers/administration/users';
import { Groups } from './reducers/administration/groups';
import { Roles } from './reducers/administration/roles';
import { LDAP } from './reducers/administration/ldap';
import { Apps } from './reducers/administration/apps';
import { Permissions } from './reducers/administration/permissions';
import { Assignment } from './reducers/administration/assignment';
//#endregion

import { Links } from './reducers/links';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const ConfigureStore = (initialState = {}, middleware = []) => {
    const store = createStore(
        combineReducers({
            config: Config,
            users: Users,
            groups: Groups,
            roles: Roles,
            apps: Apps,
            permissions: Permissions,
            ldap: LDAP,
            assignment: Assignment,
            links: Links,
        }),
        composeEnhancers(applyMiddleware(thunk, ...middleware))
    );

    return store;
};
