import * as ActionTypes from '../../administration/roles/ActionTypes';

export const Roles = (
    state = {
        isLoading: true,
        errMess: null,
        roles: [],
        rolesUserGroup: [],
        newRoleName: '',
        newRoleDescription: '',
        checkedRoles: [],

        assignedRoles: [],

        isUpdating: false,
        isCreating: false,
        updateErrMess: null,
        createErrMess: null,
    },
    action
) => {
    switch (action.type) {
        case ActionTypes.LOAD_ROLES:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                roles: action.payload,
            };
        case ActionTypes.ROLES_LOADING:
            return { ...state, isLoading: true, errMess: null, roles: [] };
        case ActionTypes.ROLES_LOAD_FAILED:
            return { ...state, isLoading: false, errMess: action.payload, roles: [] };
        case ActionTypes.LOAD_USER_ROLES_GROUP:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                rolesUserGroup: action.payload,
            };
        case ActionTypes.UPDATE_NEW_ROLE_NAME:
            return { ...state, newRoleName: action.payload };
        case ActionTypes.UPDATE_NEW_ROLE_DESCRIPTION:
            return { ...state, newRoleDescription: action.payload };
        case ActionTypes.SET_CHECKED_ROLES:
            return { ...state, checkedRoles: action.payload };
        case ActionTypes.SET_ASSIGNED_ROLES:
            return { ...state, assignedRoles: action.payload };
        case ActionTypes.ROLES_UPDATING:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                updatingErrMess: null,
                isUpdating: true,
            };
        case ActionTypes.ROLES_UPDATING_FAILED:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                updatingErrMess: action.payload,
                isUpdating: false,
            };
        case ActionTypes.ROLES_UPDATED:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                updatingErrMess: null,
                isUpdating: false,
            };
        case ActionTypes.ROLES_CREATING:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                creatingErrMess: null,
                isCreating: true,
            };
        case ActionTypes.ROLES_CREATING_FAILED:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                creatingErrMess: action.payload,
                isCreating: false,
            };
        case ActionTypes.ROLES_CREATED:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                creatingErrMess: null,
                isCreating: false,
            };
        default:
            return state;
    }
};
