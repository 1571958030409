import * as ActionTypes from '../../administration/apps/ActionTypes';
import keyBy from 'lodash/keyBy';

export const Apps = (
    state = {
        isLoading: true,
        errMess: null,
        apps: [],
        modules: [],
    },
    action
) => {
    switch (action.type) {
        case ActionTypes.LOAD_APPS:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                apps: action.payload,
            };
        case ActionTypes.APPS_LOADING:
            return { ...state, isLoading: true, errMess: null };
        case ActionTypes.LOAD_APP_MODULES:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                modules: { ...state.modules, ...keyBy(action.payload, 'moduleId') },
            };
        default:
            return state;
    }
};
