import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    progressReportContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 1001,
        backgroundColor: '#d8d8d854',
        overflow: 'hidden',
    },
    progressSpinner: {
        '& > div > div': {
            backgroundColor: '#00AFE5',
        },
    },
    progressContainer: {
        position: 'absolute',
        zIndex: 1000,
        backgroundColor: '#e3e3e3',
        borderRadius: '4px',
        left: 0,
        right: 0,
        overflow: 'hidden',
    },
    actionText: {
        fontWeight: 500,
    },
    progressReportText: {
        color: '#00AFE5',
        fontWeight: 500,
        textTransform: 'uppercase',
    },
});

export const ActionProgressReportType = {
    LOADING: 'Loading',
    CREATING: 'Creating',
    UPDATING: 'Updating',
    SAVING: 'Saving',
    DELETING: 'Deleting',
    EXPORTING: 'Exporting',
};

const LoadingEMI = `${process.env.PUBLIC_URL}/images/Loading_emi.gif`;

export function LoaderBg(props) {
    const classes = useStyles();
    return (
        <Box className={classes.progressReportContainer} height={1} width={1} display="flex" justifyContent="center" alignItems="center">
            <Loader isRelative={true} {...props} />
        </Box>
    );
}

export function LoaderFixedPosition(props) {
    return <Loader {...props} style={{ position: 'fixed' }} />;
}

export const Loader = ({ action, isRelative = true, style }) => {
    const classes = useStyles();

    return (
        <Box height={1} width={1} className={isRelative ? '' : classes.progressContainer} style={style || {}}>
            <Box width={1} height={1} display="flex" alignContent="center" textAlign="center">
                <Box width={1} display="flex" justifyContent="center">
                    <Box display="flex" alignItems="center" justifyContent="center" className={classes.progressSpinner}>
                        <img alt="LOADING" width="100px" height="100px" src={LoadingEMI} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
