export const LOAD_GROUPS = 'LOAD_GROUPS';
export const GROUPS_LOADING = 'GROUPS_LOADING';
export const GROUPS_LOAD_FAILED = 'GROUPS_LOAD_FAILED';
export const LOAD_USER_GROUPS = 'LOAD_USER_GROUPS';
export const LOAD_ROLE_GROUPS = 'LOAD_ROLE_GROUPS';
export const LOAD_ASSIGNED_ROLES = 'LOAD_ASSIGNED_ROLES';

export const GROUPS_UPDATING = 'GROUPS_UPDATING';
export const GROUPS_UPDATING_FAILED = 'GROUPS_UPDATING_FAILED';
export const GROUPS_UPDATED = 'GROUPS_UPDATED';

export const GROUPS_CREATING = 'GROUPS_CREATING';
export const GROUPS_CREATING_FAILED = 'GROUPS_CREATING_FAILED';
export const GROUPS_CREATED = 'GROUPS_CREATED';
