import React from 'react';
import { makeStyles, Avatar } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles({
    linked: {
        color: green[500],
        border: 'solid 2px',
        padding: '2px',
        borderColor: green[600],
        fontWeight: 'bold',
        width: '30px',
        fontSize: '8px',
        height: '16px',
        background: 'transparent',
    },
});

const LdapLinked = props => {
    const classes = useStyles();
    return (
        <Avatar variant="rounded" className={classes.linked}>
            LDAP
        </Avatar>
    );
};

export default LdapLinked;
