import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';
import { ConfigureStore } from './redux/configureStore';
import { Provider } from 'react-redux';
import { RootErrorBoundary } from '@snc/ui';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.scss';
import { ThemeProvider } from 'components/Theme/ThemeProvider';

const store = ConfigureStore();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});

ReactDOM.render(
    <CookiesProvider>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <RootErrorBoundary
                    baseUrl={process.env.PUBLIC_URL}
                    buttonProps={{ style: { backgroundColor: '#FF4347', color: '#FFF' } }}
                    iconProps={{ style: { color: '#FF4347' } }}
                >
                    <App />
                </RootErrorBoundary>
            </Provider>
        </QueryClientProvider>
    </CookiesProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
