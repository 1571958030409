import * as ActionTypes from './ActionTypes';

import merge from 'lodash/merge';

const CONFIG_URL = localStorage.getItem('emiSuiteConfigUrl') || '/emi-suite-config/config.json';

export const fetchConfig = () => dispatch =>
    fetch(CONFIG_URL)
        .then(response => {
            if (!response.ok) {
                if (process.env.NODE_ENV !== 'production') return {};
                throw new Error('Error ' + response.status + ': ' + response.statusText);
            }
            const config = response.json().catch(() => {
                if (process.env.NODE_ENV !== 'production') return {};
                throw new Error(`Response from '${CONFIG_URL}' is not a valid JSON object.`);
            });
            return config;
        })
        .then(appConfig => {
            dispatch(configLoad(merge(appConfig, getLocalConfig())));
        })
        .catch(err => dispatch(configLoadFailed(err.message)));

export const configLoading = () => ({
    type: ActionTypes.LOAD_CONFIG_LOADING,
});

export const configLoad = appConfig => ({
    type: ActionTypes.LOAD_CONFIG,
    payload: appConfig,
});

export const configLoadFailed = errMess => ({
    type: ActionTypes.LOAD_CONFIG_FAILED,
    payload: errMess,
});

const getLocalConfig = () => {
    const config = localStorage.getItem('emiSuiteConfig') || '{}';
    try {
        return JSON.parse(config);
    } catch {
        return {};
    }
};
