import * as ActionTypes from '../link/ActionTypes';

export const Links = (
    state = {
        launcher: null,
        links: [],
    },
    action
) => {
    switch (action.type) {
        case ActionTypes.LOAD_LINKS:
            return {
                ...state,
                launcher: action.launcher,
                links: action.links.sort((a, b) => a.position - b.position),
            };

        default:
            return state;
    }
};
