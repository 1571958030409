export const LOAD_LINKS = 'LOAD_LINKS';
export const LOAD_LINKS_FAILED = 'LOAD_LINKS_FAILED';
export const LINKS_LOADING = 'LINKS_LOADING';

export const LINK_POSTED = 'LINK_POSTED';
export const LINK_POSTING = 'LINK_POSTING';
export const LINK_POSTED_FAILED = 'LINK_POSTED_FAILED';

export const LINK_PUTTED = 'LINK_PUTTED';
export const LINK_PUTTING = 'LINK_PUTTING';
export const LINK_PUTTED_FAILED = 'LINK_PUTTED_FAILED';

export const LINK_DELETED = 'LINK_DELETED';
export const LINK_DELETING = 'LINK_DELETING';
export const LINK_DELETED_FAILED = 'LINK_DELETED_FAILED';
