import * as ActionTypes from '../../administration/permissions/ActionTypes';

export const Permissions = (
    state = {
        isLoading: true,
        errMess: null,
        permissions: [],
        checkedPermissions: [],
    },
    action
) => {
    switch (action.type) {
        case ActionTypes.LOAD_PERMISSIONS:
            const currentPermissions = [
                ...state.permissions,
                ...action.payload.filter(permission => !state.permissions.includes(permission)),
            ];
            return {
                ...state,
                isLoading: false,
                errMess: null,
                permissions: currentPermissions,
            };
        case ActionTypes.PERMISSIONS_LOADING:
            return { ...state, isLoading: true, errMess: null, permissions: [] };
        case ActionTypes.PERMISSIONS_LOAD_FAILED:
            return {
                ...state,
                isLoading: false,
                errMess: action.payload,
                permissions: [],
            };
        case ActionTypes.PERMISSIONS_LOAD_CHECKED:
            return {
                ...state,
                checkedPermissions: action.payload,
            };
        case ActionTypes.SET_CHECKED_PERMISSIONS:
            return { ...state, checkedPermissions: action.payload };
        default:
            return state;
    }
};
