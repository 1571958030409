import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const CustomDialogTitle = props => {
    const { children, handleCloseDialog, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
            <Typography variant="h6">{children}</Typography>
            <IconButton
                style={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
                aria-label="close"
                onClick={handleCloseDialog}
            >
                <CloseIcon />
            </IconButton>
        </MuiDialogTitle>
    );
};
export default CustomDialogTitle;

CustomDialogTitle.propTypes = {
    handleCloseDialog: PropTypes.func.isRequired,
};
