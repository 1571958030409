import React from 'react';
import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
import clsx from 'clsx';

const useStyles = makeStyles({
    ce0: {
        color: red[500],
        fontWeight: 'bold',
    },
    cgt0: {
        color: green[400],
        fontWeight: 'bold',
    },
});

const GroupUsersCount = props => {
    const classes = useStyles();
    const { count } = props;
    const labelClassName = clsx({
        [classes.ce0]: count === 0,
        [classes.cgt0]: count > 0,
    });

    return <span className={labelClassName}>{count}</span>;
};

export default GroupUsersCount;
